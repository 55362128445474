<template>
  <div class="mailling__container" style="margin-top: -30px;">
    <AppTitleSettingParam style="margin: 10px 0">{{ title }}</AppTitleSettingParam>
    
    
    <div class="mailling__images" v-if="imagesCropped.length">
      <div 
        v-for="(image, index) in imagesCropped" 
        :key="index" 
        class="mailling_image-container"
      >

        <img style="width: 90%;" :src="'https://lk.ivitrina.store/medias/'+image.link+ 'thumb/' + image.name + '.webp'">
        <svg class="mailling__delete-img"
             @click="deleteImg(index)"
             width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M11.8499 0.535925L13.707 2.39308L2.39331 13.7068L0.536157 11.8496L11.8499 0.535925ZM2.71114 0.602339L14.0248 11.916L11.9276 14.0133L0.613907 2.69957L2.71114 0.602339Z"
              fill="#20A0FF"/>
        </svg>
      </div>
    </div>

    <div class="button-add__container">
      <AppButtonAdd @click="openWindow">
        <span class="mailling__name">
          {{ imagesCropped.length ? 'Добавить ещё' : 'Добавить' }} {{ button }}
        </span>
        <br>
        <span 
          v-if="alertShow" 
          style="color:red; font-size: 12px;"
        >Чтобы добавить изображение, необходимо удалить видео</span>
      </AppButtonAdd>
    </div>

    <teleport to="body">
      <div class="mailling__cropper-container"
           :ref="'cropCont'"
           v-if="showCropImageStock" 
           @click.stop="closeCropper"
      >
        <div class="crop-mailling_image-container">
          <div class="header-cropper-img">
            <span> {{ 'Добавление ' + headerPart }} </span>
            <svg @click="closeCropper2()"
                 style="cursor: pointer"
                 width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect y="19.7996" width="28" height="4" transform="rotate(-45 0 19.7996)" fill="black"/>
              <rect width="28" height="4" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 22.627 19.7996)"
                    fill="black"/>
            </svg>
          </div>
          <AppCropImage
              :combo="combo"
              :destroyImage="destroyImage"
              :type="'mailling'"
              :shop_id="shop_id"
              :ref="'AppCropImage'"
              :ratio="[ratio.join(':')]"
              :showButtonCrop="false"
              :extension="['png', 'jpg', 'jpeg']"
              @imageData="addNewImgBanner"
              @addImage="addImage"
              @destCropper="destCropper"
          />
        </div>
      </div>
    </teleport>
  </div>
</template>


<script>
import AppCropImage from '@/components/AppCropImage';

export default {
  name: "AppAddImage",
  components: {
    AppCropImage
  },
  props: {
    media_type: {
      type: String,
      default: 'ff',
    },
    desctroyImage: {
      type: Boolean,
      default: false,
    },
    combo: {
      type: Boolean,
      default: false,
    },
    shop_id: {
      type: [String, Number],
    },
    photo: {
      type: String,
    },
    button: {
      type: String,
    },
    title: {
      type: String,
    },
    ratio: {
      type: Array,
    },
    headerPart: {
      type: String,
      default() {
        return 'нового баннера*';
      },
    },
  },
  data() {
    return {
      destroyImage: true,
      imagesCropped: [], // Массив для хранения загруженных изображений
      showCropImageStock: false,
    };
  },
  computed: {
    alertShow() {
      if (!this.combo) return false;
      if (this.media_type == 'video') return true;
      if (!this.imagesCropped.length && this.media_type == 'video') return true;
      return false;
    },
  },
  mounted() {
   
    if (Array.isArray(this.photo)) {
      this.imagesCropped = [...this.photo]; // Если `photo` массив, копируем его
    } else if (this.photo) {
     
      // Если `photo` строка, преобразуем в массив
      this.imagesCropped = [this.photo];
    }
  },
  methods: {
    openWindow() {
      if (this.alertShow) return;
      this.showCropImageStock = true;
    },
    deleteImg(index) {
      this.imagesCropped.splice(index, 1); // Удаляем по индексу
      this.$emit('deleteImg', this.imagesCropped); // Передаём обновлённый массив
    },
    addNewImgBanner(imgData) {
  
      //const newImage = "https://lk.ivitrina.store/medias" + imgData.link + "thumb/" + imgData.name + ".webp";
      if (!this.imagesCropped.includes(imgData)) {
        this.imagesCropped.push(imgData); // Добавляем новое изображение
        this.$emit('imgData', this.imagesCropped); // Передаём весь массив
      }
      this.showCropImageStock = false;
    },
    closeCropper2() {
      this.destroyImage = !this.destroyImage;
    },
    closeCropper(ev) {
      if (this.$refs.cropCont === ev.target) {
        this.destroyImage = !this.destroyImage;
      }
    },
    destCropper() {
      this.showCropImageStock = false;
    },
  },
  watch: {
    photo(newPhoto) {
      if (Array.isArray(newPhoto)) {
        this.imagesCropped = [...newPhoto]; // Если массив, копируем
      } else if (newPhoto) {
        this.imagesCropped = [newPhoto]; // Если строка, преобразуем
      } else {
        this.imagesCropped = [];
      }
    },
  },
};
</script>

<style scoped> 
.mailling__cropper-container {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(56, 55, 55, 0.65);
  z-index: 20;
}

.crop-mailling_image-container {
  background: white;
  border: 1px solid rgba(65, 65, 65, 0.75);
  border-radius: 6px;
  padding: 15px;
  position: relative;
}

.header-cropper-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 80px;
  z-index: 20;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 6px;
}

.mailling__delete-img {
  
  cursor: pointer;
}
.mailling_image-container {
  width: 20%;
}
.mailling_image-container img {
  margin: 0px;
  height: 100%;
  width: auto;
}

.mailling__image {
  padding-top: 7px;
  display: flex;
  align-items: center;
  justify-content: start;
}

.button-add__container {
  margin-top: -5px;
  color: #20A0FF;
  display: flex;
  align-items: center;
  justify-content: start;
}
.mailling__name {
  color: var(--default_background);
}
</style>