<template>
  <div class="chats-table chats__container">
    <div class="chats-table__content block-style" > <!--v-if="test"-->
        <!--
      <div style="display: flex;">
     
        <AppButton
          @click="ratioType('individ')"
          style="width: 49%; height: 30px; margin-right: 20px;  margin-bottom: 10px; cursor: pointer;"
          :styleButton="getStyle('individ')"
              > Индивидуальная 
        </AppButton>
        <AppButton
          @click="ratioType('mass')"
          style="width: 49%; height: 30px; margin-bottom: 10px; cursor: pointer;"
              :styleButton="getStyle('mass')"> Массовая 
        </AppButton>
      </div>
      {{selectType=='individ'? 'индивидуальную' : 'массовую'}}
      -->
      
     
        <AppButton
            @click="addModel()"
        >
                    <span class="products__button_text">
                        Добавить  рассылку
                    </span>
          <svg class="products__button_svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M16 6.41509V9.04151H0V6.41509H16ZM9.49098 0V16H6.52505V0H9.49098Z" fill="white"/>
          </svg>
        </AppButton>
        <div class="container-rule" >
      
          <AppSearchAndFilter style="margin-top: 10px;"
              class="client__search"
              @goSearch="goSearch"
              @filtered="filtered"
              :hiddenFilter="true"
              :filterContent="{}"
              />
     
        </div>

      <AppLoadingOverlay v-if="loadModels"/>
      
      <ul class="order-filter__type_ul" v-if="!loadModels">
        <li class="product-edit__status_li"
            v-for="(mailing, i) in mailingList"
            :key="i">
            <MaillingShortElement 
            :shop_elements="shop_elements"
            :element="mailing" 
            :statuses="statuses" 
            :jobs="jobs" 
            @goDelete="goDelete" 
            @goUpdate="goUpdate"
            />
        </li>
      </ul>
      <div style="display: flex; justify-content: center">
       
        <AppButton 
            @click="prevPage" 
            style="width: 100px; height: 30px; margin-top: 16px; margin-right: 10px; cursor: pointer;"
            
            :styleButton="'_blue-external'"
        >
          Предыдущая
        </AppButton>
        <AppButton 
            @click="nextPage"
            style="width: 100px; height: 30px; margin-top: 16px; margin-right: 10px; cursor: pointer;"
            :styleButton="'_blue-external'"
        >
          Следующая
        </AppButton>
      </div>
    </div>

  </div>
</template>

<script>
import api from "./api"
import MaillingShortElement from './MaillingShortElement';

export default {
  name: "MaillingList",
  components: {MaillingShortElement}, 
  props: {
    mailingData:{
      type: Object,
      required: true
    },
  },
  data() {
    return {
      loadModels: false,
      statuses: [],
      jobs: [],
      selectType:'mass',
      timeZon: localStorage.getItem('timeZone'),
      shop_id: localStorage.getItem('shop_id'), 
      shop_elements: localStorage.getItem('shop_elements'), 
      mailingList: [],
      page:1,
      pages:1,
      pageSize:10,
      statusIcon: {
        Draft: '' +
            '<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path fill-rule="evenodd" clip-rule="evenodd" d="M8.17109 0.0279265C8.20538 0.044205 8.6259 0.450914 9.10559 0.931746C9.87861 1.70663 9.9793 1.81452 9.99154 1.88114C10.0218 2.04607 9.99996 2.07582 9.42222 2.65606L8.88713 3.19347L7.84638 2.15291L6.80564 1.11235L7.32337 0.594138C7.63294 0.284279 7.87252 0.0603468 7.91926 0.0371504C8.01282 -0.00928155 8.08699 -0.0119979 8.17109 0.0279265ZM7.43078 2.57791L8.46628 3.61366L6.03363 6.04662C4.69567 7.38475 3.59221 8.47959 3.58148 8.47959C3.55594 8.47959 1.51991 6.44354 1.51991 6.418C1.51991 6.39873 6.36618 1.54216 6.38541 1.54216C6.39084 1.54216 6.86125 2.00824 7.43078 2.57791ZM3.06801 8.83359C3.03438 8.86453 0.386631 9.98677 0.322833 9.99713C0.152014 10.0249 -0.0249988 9.84683 0.0029239 9.67537C0.0093721 9.63572 0.261731 9.02726 0.563722 8.32325C0.865712 7.61925 1.12557 7.01282 1.14121 6.97563L1.16962 6.90804L2.12591 7.86427C2.65188 8.3902 3.07582 8.8264 3.06801 8.83359Z" fill="#8EA2B0"/>\n' +
            '</svg>\n',
        Sent: '' +
            '<svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path fill-rule="evenodd" clip-rule="evenodd" d="M7.33777 0.548282C7.64045 0.849835 7.88814 1.12254 7.88814 1.15426C7.88814 1.18593 6.81062 2.28919 5.49358 3.60595L3.09902 6L1.54951 4.45122C0.697311 3.59941 0 2.87742 0 2.84677C0 2.77324 1.08006 1.69032 1.15342 1.69032C1.18463 1.69032 1.63527 2.11487 2.15481 2.63368L3.09942 3.5771L4.888 1.78852C5.87165 0.804872 6.70148 0 6.73196 0C6.76244 0 7.03509 0.24673 7.33777 0.548282Z" fill="#8EA2B0"/>\n' +
            '</svg>\n',
        Delivered: '' +
            '<svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path fill-rule="evenodd" clip-rule="evenodd" d="M7.33777 0.548282C7.64045 0.849835 7.88814 1.12254 7.88814 1.15426C7.88814 1.18593 6.81062 2.28919 5.49358 3.60595L3.09902 6L1.54951 4.45122C0.697311 3.59941 0 2.87742 0 2.84677C0 2.77324 1.08006 1.69032 1.15342 1.69032C1.18463 1.69032 1.63527 2.11487 2.15481 2.63368L3.09942 3.5771L4.888 1.78852C5.87165 0.804872 6.70148 0 6.73196 0C6.76244 0 7.03509 0.24673 7.33777 0.548282Z" fill="#2CBA15"/>\n' +
            '</svg>\n',
        Read: '' +
            '<svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path fill-rule="evenodd" clip-rule="evenodd" d="M7.33777 0.548282C7.64045 0.849835 7.88814 1.12254 7.88814 1.15426C7.88814 1.18593 6.81062 2.28919 5.49358 3.60595L3.09902 6L1.54951 4.45122C0.697311 3.59941 0 2.87742 0 2.84677C0 2.77324 1.08006 1.69032 1.15342 1.69032C1.18463 1.69032 1.63527 2.11487 2.15481 2.63368L3.09942 3.5771L4.888 1.78852C5.87165 0.804872 6.70148 0 6.73196 0C6.76244 0 7.03509 0.24673 7.33777 0.548282Z" fill="#2CBA15"/>\n' +
            '<path fill-rule="evenodd" clip-rule="evenodd" d="M10.3964 0.548282C10.6991 0.849835 10.9468 1.12254 10.9468 1.15426C10.9468 1.18593 9.86929 2.28919 8.55226 3.60595L6.1577 6L5 4.73356C5 4.73356 5.0029 4.76421 5.0029 4.73356C5.0029 4.66003 6.08295 3.5771 6.15631 3.5771C8.10231 5.46389 5.63855 3.05829 6.15809 3.5771L7.94667 1.78852C8.93032 0.804872 9.76016 0 9.79064 0C9.82112 0 10.0938 0.24673 10.3964 0.548282Z" fill="#2CBA15"/>\n' +
            '</svg>\n',
      },
      statusMessage: {
        Draft: 'Черновик',
        Sent: 'Отправлено',
        Delivered: 'Доставлено',
        Read: 'Прочитано',
      },
      showMessageForm: false,
      answer: "",
      search:"",
      filterContent: {
        title: 'Фильтр по товарам',
        filters: {
          sort: {
            title: 'Сортировать:',
            type: 'radio',
            current: "",
            items: [
              {id: 'created_at,asc', name: 'По дате рассылки ↓'},
              {id: 'created_at,asc', name: 'По дате рассылки ↑'},
            ],
          },
          status: {
            title: 'Выбрать статус',
            type: 'radio',
            elem: 'filter',
            name: 'status',
            items: [
              {id: 'all', name: 'Выбрать все'},
              {id: 'clients', name: 'Клиенты'},
              {id: 'visitor', name: 'Посетители'},
            ],
            current: "",
          }
        }
      },
      filterState: {
        title: 'Фильтр по товарам',
        filters: {
          status: {
            type: 'radio',
            elem: 'filter',
            name: 'status',
            items: [
              {id: 'all', name: 'Все'},
              {id: 'clients', name: 'Черновик'},
              {id: 'visitor', name: 'Отправлено'},
            ],
            current: "",
          }
        }
      },
      currentFilterState: '',
    }
  },
  mounted(){ 
    this.getStatuses()
    this.getJobs()
    this.getModels()
    // Запускаем интервал с отправкой запроса каждую минуту
    this.interval = setInterval(this.getElementStatuses, 60000);
  },
  unmounted() {
    // Очищаем интервал при уничтожении компонента (важно для предотвращения утечек памяти)
    clearInterval(this.interval);
  },
  methods: {
    prevPage(){
      if (this.page==1 ) return
      if (this.page<1 ) this.page = 2
      this.page = this.page - 1
      this.getModels()
    },
    nextPage(){
      if (this.page==this.pages ) return
      if (this.page>this.pages ) this.page =this.pages-1
      this.page = this.page + 1
      this.getModels()
    },
    goSearch(input){
      this.search = input
      this.getModels()
    },
    async getElementStatuses(){
        let data = {"maillingIds": this.mailingList.map(obj => obj.id)};
        const response = await api.getElementStatuses(data)
        let responseData = response.data
        this.mailingList = this.mailingList.map(item => {
        const matchingResponse = responseData.find(responseItem => responseItem.id === item.id);

        // Если найдено совпадение по id, обновляем значения
        if (matchingResponse) {
          item.status = matchingResponse.status;
          item.job = matchingResponse.job;
        }

        return item;
      });
    },
    async getStatuses(){
        const response = await api.getMaillingStatuses()
        this.statuses = response.data
       
    },
    async getJobs(){
        const response = await api.getMaillingJobs()
        this.jobs = response.data
    },
    ratioType(type){
      this.selectType = type
    },
    getStyle(type){
        return this.selectType === type ? null : '_blue-external';
    },
    addModel(){
      this.$router.push({ name: "MaillingElement", params: { id: "create", type:this.selectType, buttons:[]}})
    },
   
    goDelete(id){
      api.deleteMailling(id).then(() => {
            this.getModels();
            })

    },
    
    goUpdate(id, element){
        let index = this.mailingList.findIndex(e=>e.id==id)
        console.log(element)
        this.mailingList[index].status = element.status
        this.mailingList[index].job = element.job
      },
    getModels(){
      this.loadModels = true
      let filter = ""
      let data = {
            shop_id:this.shop_id,
            page:this.page,
            pageSize:this.pageSize,
            search:this.search,
            filter: filter
        }
        api.getMaillings(data).then((response) => {
                        this.mailingList  = response.data.data
                        this.loadModels = false
                        console.log(response.data)
                        this.pages = response.data.meta.pages
                        this.page = response.data.meta.currentPage
                        this.pageSize = response.data.meta.pageSize
                      //  console.log(this.mailingList)
                    }).catch((error) => {
                        if (error.response.data)
                            this.showMessageNotice('Ошибка 09', error.response.data,'error')
                        if (error.response.data.message)
                            this.showMessageNotice('Ошибка 09', error.response.data.message,'error')
                    })
        
    },

    filtered(data) {
      this.$emit('data', {action: 'filterContent', value: data})
    },
    
   
  },
}

</script>

<style lang="scss" scoped>
 @import '@/style/vars';
  .dimmed {
    opacity: 0.5;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .radio, .checkbox {
      margin-top: 10px;
      background: #EEF4F8;
      border: 1px solid #E4E7ED;
      border-radius: 90%;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 20px;
      width: 20px;
      height: 20px;
      &:hover {
          border: 1px solid var(--default_background_hover);
      }
      &:active {
          border: 1px solid var(--default_background_active);
      }
  }
  .checkbox__svg {
  opacity: 0;
  transition: 200ms;
}

  .checkbox__input:checked + .checkbox__svg {
    opacity: 1;
  }

  .radio__input {
      width: 100%;
      height: 100%;
      &:checked + .radio__svg {
          opacity: 1;
      }
  }
  .radio__svg {
      opacity: 0;
      transition: 200ms;
  }
  .radio_div{
    cursor: pointer;
    display: flex;
    width: 50%;
    margin: 5px;
    align-items: center;  /* Центрирует элементы по вертикали */
    justify-content: space-between;  /* Располагает элементы равномерно по горизонтали */
  }
    .radio_button_text_info{
        display: flex;
    }
    .radio_button {
      font-size: 14px;
      padding: 5px 8px;
      border-radius: 10px;
      background: #fff;
      margin: 5px;
      border: #0000;
    }

    .radio__text {
        color: #7E7E7E;
        margin-top: -5px;
        font-size: 12px;
    }
.chats-table__content {

  padding: 11px 13px;
  margin-bottom: 14px;
}

.chats {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
}

.chat {
  position: relative;
  padding-top: 8px;

  &::before {
    background: var(--global_block_line);
    content: "";

    position: absolute;
    top: 0;
    height: 2px;
    width: 100%;
  }

  &:first-child {
    padding-top: 0;

    &::before {
      background: none;
      width: 0;
      height: 0;
    }
  }
}

.chat__info {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  font-size: 12px;

  .chat__price {
    font-size: 12px;
    text-align: right;
  }
}

.chat__id {
  color: var(--default_background);
  font-size: 14px;
  font-weight: 500;
}

.chat__message {
  color: var(--global_third_color);
  font-size: 10px;
}

.chat__user {
  color: var(--global_second_color);
  font-size: 14px;
}

.chat__additional-info_span {
  font-size: 12px;
}

.chat__interaction_text-span {
  font-size: 12px;
}

.chat__interaction {
  border-top: 1px solid var(--global_block_line);
  margin-top: 9px;
  padding-top: 9px;
}

.chat__interaction__form {
  display: flex;
  flex-direction: column;
  row-gap: 11px;

  .chat__interaction_button {
    width: 82px;
  }
}

.chat__interaction_hint {
  font-size: 12px;
}

.chat__interaction__form_textarea {
  height: 76px;
  margin-bottom: 5px;
}

.chat__interaction_buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.products__button {
  margin: 10px 0;
}

.chat__interaction_button {
  column-gap: 5px;
  width: 131px;
  height: 30px;
}

.order-filter__type_ul {
  display: flex;
  justify-content: space-between;
}

.order-filter__type_ul {
  display: flex;
  flex-wrap: wrap;
}

.order-filter {
  display: flex;
  justify-content: space-between;
}

.product-edit__status_li {
  width: 100%;
  margin: 10px 0;
}

.products__button_svg {
  margin-left: 10px;
}

.date-naw {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 8px;
}

.status-message {
  font-family: Roboto;
  color: #8EA2B0;
  font-size: 9px;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.header-mailing {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin: 12px 0;
}
.views {
  color: #8EA2B0;
  font-size: 9px;
}

.button-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.button-block2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-description {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: rgba(0, 0, 0, 0.4);
  margin: 12px 0;
}

.img-mailing {
  min-width: 280px;
  height: auto;
}

</style>