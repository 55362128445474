<template>
  <div class="about-setting block-style">
    <div style="display: flex;" v-if="shop_elements.find((e) => e == 'PageTask')">
    </div>
    <div style="display: flex;">
      <AppButton style="width: 49%; height: 30px; margin-right: 20px;  margin-bottom: 10px; cursor: pointer;"> Основное >> </AppButton>
      <AppButton
        @click="goStep(2)"
        style="width: 49%; height: 30px; margin-bottom: 10px; cursor: pointer; margin-right: 20px;"
        styleButton="_blue-external"
      >
        Фильтры >>
      </AppButton>
      <AppButton
        @click="goStep(3)"
        style="width: 49%; height: 30px; margin-bottom: 10px; cursor: pointer; margin-right: 20px;"
        styleButton="_blue-external"
      >
        Боты >>
      </AppButton>
      <AppButton
        @click="goStep(4)"
        style="width: 49%; height: 30px; margin-bottom: 10px; cursor: pointer; margin-right: 20px;"
        styleButton="_blue-external"
      >
        Настройки >>
      </AppButton>
      <AppButton
        @click="goStep(5)"
        style="width: 49%; height: 30px; margin-bottom: 10px; cursor: pointer;"
        styleButton="_blue-external"
      >
        Дата/Время
      </AppButton>
    </div>
    <div class="about-setting block-style">
      <AppInput
        v-model="model.name"
        :placeholder="'Заголовок рассылки'"
        :cssClass="'input-mailing'"
        :forAndId="'name'"
      >
        <template v-slot:title>
          <AppTitleSettingParam></AppTitleSettingParam>
        </template>
      </AppInput>
      <EditableText
        v-model="model.message.text"
        :title="'Текст рассылки'"
        :LengthText="'Внимание! Максимальное количество знаков с пробелами - 970'"
        :LengthTextInfo="'Использовано: ' + (model.name.length + model.message.text.length) + ' / 970'"
        :MaxLength="1000"
      />
      <div>
        <AppAddImage
          :media_type="model.message.all_media_items_type"
          :combo="true"
          :shop_id="shop_id"
          @imgData="addImage"
          @deleteImg="deleteImage"
          :button="'изображения'"
          :headerPart="'изображений'"
          :ratio="[16, 9]"
          :photo="model.message.all_media_items"
        />
      
        <AppAddVideo
          :media_type="model.message.media_type"
          :combo="true"
          :shop_id="shop_id"
          :button="'видео'"
          :headerPart="'видео'"
          :video="model.message.media"
          @uploadVideo="uploadVideo"
        />
        <AppChatButtons
          :model_id="model.id"
          :button_hidden="model.button_hidden"
          :place="'mass_mailling'"
          :shopInfo="shopInfo"
          :buttons="model.buttons"
          @updateButtons="updateButtons"
          @buttonsHidden="buttonsHidden"
          @prefSave="prefSave"
        />
      </div>
      <div v-if="model.type == 'mass'">
        <p>Внимание! Если Вас нет в Боте, в который Вы отправляете тестовую рассылку, Вы получите ошибку</p>
        <AppSelectApiSearch
          :placeholder="'Начните вводить название бота, в который будут отправлять тестовую рассылку'"
          v-model="model.availableShop_id"
          @search="searchShops"
          :items="availableShops"
        />
      </div>
    </div>
    <div style="display: flex; justify-content: right">
      <AppButton
        @click="sendTest"
        style="width: 83px; height: 30px; margin-top: 16px; margin-right: 10px;"
        :styleButton="'_blue-external'"
      >
        Тест
      </AppButton>
      <AppButton
        @click="goStep(2)"
        style="width: 83px; height: 30px; margin-top: 16px; margin-right: 10px;"
        :styleButton="'_blue-external'"
      >
        Далее
      </AppButton>
      <AppButton
        @click="prefSave"
        style="width: 83px; height: 30px; margin-top: 16px"
        :styleButton="'_blue-external'"
      >
        Сохранить
      </AppButton>
    </div>
  </div>
</template>

<script>
import api from "../api";
import AppChatButtons from "@/components/AppChatButtons";

export default {
  name: "MaillingElementCreate",
  props: {
    element: {
      type: Object,
    },
    update: {
      type: Boolean,
    },
    availableShops: {
      type: Array,
    },
    shopInfo: {
      type: Object,
    },
  },
  components: { AppChatButtons },
  data() {
    return {
      selectType: "standart",
      shop_elements: JSON.parse(localStorage.getItem("shop_elements")) || [],
      availableShop_id: null,
      model: this.element,
      localVideo: null,
      showMessageForm: false,
      answer: "",
      tasks: [],
      shop_id: localStorage.getItem("shop_id"),
      currentFilterState: "",
    };
  },
  methods: {
    addImage(imageData) {
      if (!Array.isArray(this.model.message.all_media_items)) {
        this.model.message.all_media_items = [];
      }
      if (!this.model.message.all_media_items.includes(imageData)) {
        this.model.message.all_media_items = imageData;
      }
      this.model.message.all_media_items_type = "image";
    },
    deleteImage(index) {
      if (Array.isArray(this.model.message.all_media_items)) {
        this.model.message.all_media_items.splice(index, 1);
        if (this.model.message.all_media_items.length === 0) {
          this.model.message.all_media_items_type = null;
        }
      }
    },
    uploadVideo(value) {
      if (!value) {
        this.model.message.media = null;
     
      } else {
        this.model.message.media = value;
        this.model.message.media_type = "video";
      }
    },
    goStep(id) {
      this.$emit("goStep", id);
    },
    prefSave() {
      console.log(this.model)
      if (this.model.message.text.length + this.model.name.length > 970) {
        this.showMessageNotice(
          "Ошибка 09",
          "Текст рассылки слишком длинный. <br> Пожалуйста сократите его до 970 символов",
          "error"
        );
        return;
      }
      api.prefSaveMailing(this.model).then(() => {
        this.showMessageNotice("Успех", "Рассылка сохранена", "success");
      });
    },
    sendTest() {
      this.model.recipient=localStorage.getItem("recipient")
      if (!this.model.availableShop_id) {
        this.showMessageNotice(
          "Ошибка 09",
          "Выберите бота, в который будет отправлена тестовая рассылка",
          "error"
        );
        return;
      }
      api.testMailing(this.model).then(() => {
        this.showMessageNotice("Успех", "Тестовая рассылка отправлена", "success");
      });
    },
  },
  watch: {
    element(newElement) {
      this.model = newElement;
      if (!Array.isArray(this.model.message.all_media_items)) {
        this.model.message.all_media_items = [];
      }
    },
  },
  inject: ["showMessageNotice"],
};
</script>




<style scoped>
.horisontal-ul li {
   display: inline-block;
   margin-left: 20px;
}
.date-naw {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 8px;
}

.about-setting {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 40px 12px;
}

.header-new-mailing {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.75);
  margin: 8px 0;
  display: flex;
  justify-content: space-between;
}

</style>