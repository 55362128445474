<template>
  <div style="margin-top: -30px;">
    <AppTitleSettingParam style="margin: 10px 0">{{title}}</AppTitleSettingParam>
    <div class="edit-cl__video" v-if="localVideo && (media_type=='video' || media_type=='ff')">
      <div :style="{width: '150px', height: '150px'}" class="image-container" >
    
        <video id="videoPrev" style=" width: 100%; height: 100%;" controls >
          <source :src="localVideo">
        </video> 
      </div>
      <svg class="edit-banner__delete-img"
           @click="deleteVideo()"
           width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.8499 0.535925L13.707 2.39308L2.39331 13.7068L0.536157 11.8496L11.8499 0.535925ZM2.71114 0.602339L14.0248 11.916L11.9276 14.0133L0.613907 2.69957L2.71114 0.602339Z"
            fill="#20A0FF"/>
      </svg> 
    </div>
    <div class="button-add__container">
        <AppButtonAdd
            @click="openWindow()"
        >
          <span class="edit-video__name">{{ (localVideo && (media_type=='video')) ? 'Изменить' : 'Добавить' }} {{button}}</span>
          <br>
          <!--<span v-if="alertShow" style="color:red;    font-size: 12px;" >Чтобы добавить видео, необходимо удалить изображение</span>-->
      </AppButtonAdd>
    </div>
    <teleport to="body">
      <div class="edit-banner__cropper-container"
           :ref="'cropCont'"
           v-if="showCrop"
           @click.stop=""
      >
        <div class="crop-image-container">
          <div class="header-cropper-img">
            <span> {{ 'Добавление ' + headerPart }} </span>
            <svg @click="close()"
                 style="cursor: pointer"
                 width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect y="19.7996" width="28" height="4" transform="rotate(-45 0 19.7996)" fill="black"/>
              <rect width="28" height="4" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 22.627 19.7996)"
                    fill="black"/>
            </svg>
          </div> 
          <AppUploadVideo
              :combo="combo"   
              :video="video"   
              :shop_id="shop_id"
              @uploadVideo="uploadVideo"
          /> 
        </div>
      </div> 
    </teleport> 
  </div>
</template>

<script>


export default {
  name: "AppAddVideo",
  components: {

  },
  props: {
    media_type: {
      type: String,
        default: 'ff',
    },
    desctroyVideo: {
        type: Boolean,
        default: false,
    },
    combo: {
      type: Boolean,
      default:false,
    },
    shop_id:{
      type: [String, Number],
    },
    video: {
      type: String,
    },
    button: {
      type: String,
    },
    title: {
      type: String,
    },
    headerPart: {
      type: String,
      default() {
        return 'новое видео*'
      }
    },
  },
  data() {
    return {
      //ratio: [10, 10],
      destroyImage:true,
      localVideo: null,
      showCrop: false,
    } 
  },
  mounted(){ 
    
      if (this.video) this.setVideo(this.video)
  },
  computed: {
        alertShow() {
          console.log(this.combo)
          if (!this.combo) return false
         
          if (this.media_type!='video') return true
          if (this.localVideo && (this.media_type=='image')) return true
          if (!this.localVideo && (this.media_type=='image')) return true
         
          return false
        }
    },
  methods: {
    openWindow(){
        //if (this.alertShow) return
        this.showCrop = true
    },

    setVideo(videoFile){

      this.localVideo = `https://lk.ivitrina.store/medias${videoFile.link}${videoFile.name}`
      var video = document.getElementById('videoPrev');
      if (!video) return
      var source = document.createElement('source');
      source.setAttribute('src', this.localVideo);
      source.setAttribute('type', 'video/mp4');
      video.load();
    }, 
    uploadVideo(data){
      this.setVideo(data)
      this.close()

      this.$emit('uploadVideo', data);
  },
    deleteVideo() {
      this.localVideo = '';
      this.$emit('uploadVideo', null);
    },
    close(){
      this.showCrop = false
    }
  },
  watch: {
  
    video() {
      if (this.video) this.setVideo(this.video)
    },
    
  }
}
</script>
<style scoped>
.edit-banner__cropper-container {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(56, 55, 55, 0.65);
  z-index: 20;
}

.crop-image-container {
  background: white;
  border: 1px solid rgba(65, 65, 65, 0.75);
  border-radius: 6px;
  padding: 15px;
  position: relative;
}

.header-cropper-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 80px;
  z-index: 20;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 6px;
}

.edit-banner__delete-img {
  margin: 15px;
  cursor: pointer;
}

.image-container img {
  height: 100%;
  width: auto;
}

.edit-cl__video {
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: start;
}

.button-add__container {
  color: #20A0FF;
  display: flex;
  align-items: center;
  justify-content: start;
}
.edit-video__name {
  color: var(--default_background);
}
</style>